/* eslint-disable vue/no-unused-components */<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar"> <div class="loader"></div> </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile"><h3>Meta Data</h3></div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                    <div class="d-flex">
                      <div>
                        <h6>
                          List of Meta Data
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h6>
                      </div>
                      <div class="ml-3">
                        <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput1.click()"
                          >Upload</b-button
                        >
                        <input
                          ref="fileInput1"
                          type="file"
                          style="display:none;"
                          @change="uploadExcel($event.target.name, $event.target.files)"
                        />
                      </div>
                      <b-button
                        class="ml-3"
                        variant="success"
                        v-if="showImport"
                        @click="importExcelToDatabase()"
                        >Import</b-button
                      >
                      <div class="ml-auto">
                        <form class="search-form mr-form">
                          <input
                            type="text "
                            class="form-control"
                            v-model="searchText"
                            @input="getAllMetaData(1)"
                            placeholder="Search Here..."
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Name</th>
                          <th>Title</th>
                          <th>Keyword</th>
                          <th>Page Type</th>
                          <th colspan="2">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in metadata" v-bind:key="data._id">
                          <td>{{ index + 1 }}</td>
                          <td>
                            <div>{{ data.name }}</div>
                          </td>
                          <td>
                            <div>{{ data.title }}</div>
                          </td>
                          <td>
                            <div>{{ data.keyword }}</div>
                          </td>
                          <td>
                            <div>{{ data.pageType }}</div>
                          </td>
                          <!-- v-repeat -->
                          <td>
                            <div class="d-flex">
                              <div class="ml-2 cursor-pointer">
                                <router-link
                                  class="btn btn-primary mr-2"
                                  :to="{ name: 'editMetaDataList', params: { id: data._id } }"
                                  append
                                >
                                  <b-icon-pencil></b-icon-pencil>
                                </router-link>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <!-- end v-repeat -->
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="metadata.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="metadata.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="getAllMetaData(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></div
></template>
<script>
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import moment from "moment";
export default {
  components: { headerCustom },
  data() {
    return {
      currentPage: 1,
      searchText: "",
      metadata: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      excelDoc: null,
      file: "",
      showImport: false
    };
  },
  created() {
    this.getAllMetaData(this.page);
  },
  methods: {
    getAllMetaData(pageNo) {
      this.metadata = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      service.getAllMetaData(this.formData, data => {
        if (data.status === 200) {
          this.metadata = data.data.result;

          this.totalCount = data.data.count;
        } else {
        }
      });
    },
    uploadExcel(coverName, coverList) {
      if (!coverList) {
        this.$toaster.error("Add a file.");
        return;
      }
      this.coverFile = coverList[0];
      const fd = new FormData();
      fd.append("file", this.coverFile, this.coverFile.name);
      service.awscoverImageUpload(fd, awsResponse => {
        if (awsResponse.status === 200) {
          this.file = awsResponse.data.file;

          var obj = { fileName: this.file };
          service.downloadExcelFile(obj, response => {
            if (response) {
              this.$toaster.success("File uploaded sucessfully");
              this.showImport = true;
            }
          });
        } else {
          this.$toaster.error("File not uploaded");
        }
      });
    },
    importExcelToDatabase() {
      var obj = {};
      service.importExcelToDatabase(obj, response => {
        if (response.status == 200) {
          this.$toaster.success("File imported sucessfully");
        } else {
          this.$toaster.error("File not imported");
        }
      });
    }
  }
};
</script>
<style></style>
